import React from 'react';
import {Link} from 'gatsby';
import Moment from 'moment';
import {Table, Row, Col} from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => (
  <Layout nav="events" >
  <Row>
    <Col md={10} mdOffset={1}>
      <div className="cart-table">
      <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Location</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            {Rows(props.pageContext.events || [])}
          </tbody>
        </Table>
      </div>
      <h3 style={{marginTop: '30px', lineHeight: '36px', textTransform: 'none', fontSize: '20px', fontWeight: 400, color: '#444', textAlign: 'center'}}>Want to meet up or do an event? <Link to="/contact">Contact us</Link></h3>
    </Col>
  </Row>
  </Layout>
)


const Rows = (events) => {
  return events.map(event => {
    event['Start Date'] = event['Start Date'] ? new Date(event['Start Date']) : event['Start Date'];
    event['End Date'] = event['End Date'] ? new Date(event['End Date']) : event['End Date'];
    var today = new Date();
    if (event.Description && new Date(event['End Date'] || event['Start Date']) >= today)
      return (
        <tr>
          <td>{event['End Date'] ? `${Moment(event['Start Date']).format('MMM Do')} to ${Moment(event['End Date']).format('MMM Do')} ${event['End Date'].getFullYear()}` : `${Moment(event['Start Date']).format('MMM Do')} ${event['Start Date'].getFullYear()}` }</td>
          <td>{event.Location}</td>
          <td>{event.Link ? <a href={event.Link} target="_blank">{event.Description}</a> : event.Description}</td>
        </tr>
      )
  })
}
